<template>
  <custom-chart
    ref="chart"
    class="lean-demand-chart"
    v-if="chartdata"
    :chart-data="chartdata"
    :options="options"
    :style="myStyle"
  ></custom-chart>
</template>

<script>
import CustomChart from "@/components/bg/Stats/CustomChart.js";
import tinycolor from "tinycolor2";

export default {
  name: "LeanDemandChart",
  props: {
    manuDemand: Array,
    myStyle: Object
  },
  components: {
    CustomChart
  },
  computed: {
    chartdata() {
      if (!this.manuDemand) {
        return false;
      }
      let retObj = { labels: [], datasets: [] };
      let tempList = {};
      this.manuDemand.forEach(order => {
        if (!tempList[order.template_id]) {
          tempList[order.template_id] = [];
        }
      });
      [...this.manuDemand]
        .sort((oa, ob) => {
          return oa.created_min - ob.created_min;
        })
        .forEach(order => {
          Object.keys(tempList).forEach(tid => {
            if (tid === order.template_id) {
              tempList[tid].push(order.qty);
            } else {
              tempList[tid].push(0);
            }
          });
          retObj.labels.push(
            this.$options.filters.minsec(order.created_min * 60000)
          );
        });
      let arrColors = tinycolor("#4247CF").analogous(
        Object.keys(tempList).length * 2,
        Object.keys(tempList).length
      );
      retObj.datasets = Object.keys(tempList)
        .sort((ta, tb) => {
          return ta.localeCompare(tb);
        })
        .map((tt, tind) => {
          return {
            template_id: tt,
            label: "#" + tt,
            backgroundColor: arrColors[tind].toHexString(),
            data: tempList[tt],
            yAxisID: "left-y-axis",
            type: "bar"
          };
        });
      return retObj;
    },
    options() {
      let retObj = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom"
        },
        scales: {
          yAxes: [
            {
              id: "left-y-axis",
              type: "linear",
              position: "left",
              scaleLabel: {
                display: false,
                labelString: "Units"
              },
              ticks: {
                suggestedMin: 0
              }
            }
          ]
        }
      };
      return retObj;
    }
  }
};
</script>

<style lang="scss">
.lean-demand-chart {
  margin-top: 1em;
  transition: max-height 0.2s ease;
}
</style>
