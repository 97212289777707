<template>
  <div class="custo-leanstations-group" v-if="retObj">
    <div
      class="bg-transit-slide bg-slide-large"
      :class="{ 'bg-transit-slide-open py-3': recap }"
    >
      <div class="columns is-centered">
        <div class="column is-8">
          <lean-shop-map
            :game="{ params: game.params, facilities: retObj }"
            :in-game="false"
          ></lean-shop-map>
        </div>
      </div>
    </div>
    <div
      class="bg-transit-slide bg-slide-large"
      :class="{ 'bg-transit-slide-open pb-3': !recap }"
    >
      <div class="pl-4 py-2">
        <b class="is-inline-block pt-3">Update all stations:</b>
        <b-button
          v-for="qbtn in Object.values(quickUpdates)"
          size="is-small"
          type="is-warning"
          class="ml-4 my-2"
          :key="qbtn.id"
          @click="openQuickUpdate = qbtn"
          rounded
          :icon-left="qbtn.icon"
          >{{ qbtn.name }}</b-button
        >
      </div>
      <div class="columns is-centered">
        <div class="column is-11">
          <div class="is-relative px-2">
            <lean-config-map
              ref="myConfigMap"
              :game="{ params: game.params, facilities: retObj }"
              :improve="false"
              @update="updateMap"
              @removefaci="removeFaci"
              @addfaci="addFaci"
              @editfaci="editFaci"
            ></lean-config-map>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :active="configFaci ? true : false"
      @close="configFaci = null"
      has-modal-card
    >
      <div class="modal-card" v-if="configFaci">
        <lean-edit-faci
          ref="leaneditfaci"
          v-model="configFaci"
          :is-new="isNewFac"
          :game="{ params: game.params, facilities: retObj }"
          @sendvalidate="updateFaciConfig"
          @updatestartingstock="updateStartingStock"
        ></lean-edit-faci>
      </div>
    </b-modal>
    <b-modal
      :active="openQuickUpdate ? true : false"
      @close="
        openQuickUpdate = null;
        isChanged = false;
      "
      has-modal-card
    >
      <div class="modal-card" v-if="openQuickUpdate">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-centered">
            Quick update: {{ openQuickUpdate.name }}
          </p>
        </header>
        <section class="modal-card-body py-2">
          <div>
            <component
              v-if="openQuickUpdate.component"
              v-bind:is="openQuickUpdate.component"
              :game="{ params: game.params, facilities: retObj }"
              :improve="true"
              :ref="openQuickUpdate.id"
              @update="quickUpdateFaci"
            ></component>
          </div>
        </section>
        <footer class="modal-card-foot buttons is-centered" v-if="isChanged">
          <b-button
            rounded
            type="is-grey"
            size="is-medium"
            @click="
              openQuickUpdate = null;
              isChanged = false;
            "
            >Close</b-button
          >
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import LeanConfigMap from "@/components/lean/Create/LeanConfigMap.vue";
import LeanEditFaci from "@/components/lean/Create/LeanEditFaci.vue";
import LeanShopMap from "@/components/lean/Play/LeanShopMap.vue";

export default {
  name: "LeanConfigStations",
  components: {
    LeanConfigMap,
    LeanEditFaci,
    LeanShopMap
  },
  computed: {
    shopMap() {
      let obj = {
        lines: [],
        colSize: this.leanParams.sizeToCol[this.currentGame.params.mapSize[0]]
      };
      for (let yy = 0; yy < this.game.params.mapSize[1]; yy++) {
        let line = { id: `ly-${yy}`, cells: [] };
        for (let xx = 0; xx < this.game.params.mapSize[0]; xx++) {
          line.cells.push({
            id: `lx-${xx}`,
            faci: this.searchFaciAtPos(xx, yy)
          });
        }
        obj.lines.push(line);
      }
      return obj;
    }
  },
  props: {
    game: Object,
    recap: Boolean
  },
  created() {
    this.initializeData();
  },
  methods: {
    updateStartingStock() {
      this.$emit("updatestartingstock");
    },
    quickUpdateFaci(event) {
      if (event.type === "changeover") {
        Object.values(event.obj).forEach(fac => {
          if (this.retObj[fac.id].params && fac.params) {
            this.retObj[fac.id].params.changeoverDuration =
              fac.params.changeoverDuration || 0;
          }
        });
      }
      if (event.type === "startingstock") {
        Object.values(event.obj).forEach(fac => {
          if (this.retObj[fac.id].params && fac.params) {
            this.retObj[fac.id].params.startingStock = {
              ...fac.params.startingStock
            };
          }
        });
        this.$emit("updatestartingstock");
      }
      if (event.type === "kanban") {
        Object.values(event.obj).forEach(fac => {
          if (this.retObj[fac.id].params && fac.params) {
            this.retObj[fac.id].params.kanbanCapa =
              fac.params.kanbanCapa || null;
            if (!fac.params.kanbanCapa && this.retObj[fac.id].params.sendAuto) {
              this.retObj[fac.id].params.sendAuto = false;
            }
          }
        });
      }
      if (event.type === "balance") {
        Object.values(event.obj).forEach(fac => {
          if (this.retObj[fac.id].params && fac.params) {
            this.retObj[fac.id].params = fac.params;
            this.retObj[fac.id].name = fac.name;
            this.retObj[fac.id].icon = fac.icon;
          }
        });
      }
      if (event.type === "jidoka") {
        Object.values(event.obj).forEach(fac => {
          if (
            this.retObj[fac.id].params &&
            fac.params &&
            fac.type === "painting"
          ) {
            this.retObj[fac.id].params.stampRemove = fac.params.stampRemove;
          }
        });
      }
      if (event.type === "balanceremove") {
        Object.values(this.retObj).forEach(fac => {
          if (fac.prioNextStation === event.obj.id) {
            fac.prioNextStation = event.obj.prioNextStation;
          }
        });
        delete this.retObj[event.obj.id];
      }
      this.$buefy.toast.open({ message: "Stations updated", queue: false });
      this.isChanged = true;
    },
    sendUpdate() {
      this.$emit("sendvalidate");
    },
    initializeData() {
      setTimeout(() => {
        this.readyWatch = true;
      }, 20);
      if (this.game) {
        this.retObj = { ...this.game.facilities };
      }
    },
    updateMap(event) {
      this.$emit("update", { type: "mapSize", obj: event.obj.mapSize });
      Object.entries(event.obj.facilities).forEach(([fid, faciData]) => {
        if (this.retObj[fid]) {
          this.retObj[fid].position = faciData.position;
        }
      });
    },
    removeFaci(faci) {
      Object.values(this.retObj).forEach(fac => {
        if (fac.prioNextStation === faci.id) {
          fac.prioNextStation = faci.prioNextStation;
        }
      });
      delete this.retObj[faci.id];
      if (this.$refs.myConfigMap) {
        this.$refs.myConfigMap.initializeData();
      }
    },
    editFaci(fac) {
      this.isNewFac = false;
      this.configFaci = fac;
    },
    addFaci(cell) {
      let checkIdAvail = 100;
      let foundId = false;
      this.isNewFac = true;
      while (foundId === false && checkIdAvail < 10000) {
        if (!Object.keys(this.retObj).includes(checkIdAvail.toString())) {
          foundId = true;
        } else {
          checkIdAvail += 100;
        }
      }
      this.configFaci = {
        id: checkIdAvail.toString(),
        prioNextStation: null,
        type: "painting",
        name: "New station",
        icon: "radiobox-marked",
        params: {
          kanbanCapa: null,
          changeoverDuration: 1000,
          loadingDuration: 300,
          stampRemove: false,
          shape: "circle",
          availShapes: ["circle", "square"],
          color: "green",
          availColors: ["green"]
        },
        position: [cell.xpos, cell.ypos]
      };
    },
    updateFaciConfig() {
      this.retObj[this.configFaci.id] = JSON.parse(
        JSON.stringify(this.configFaci)
      );
      Object.values(this.retObj).forEach(fac => {
        if (
          fac.id !== this.configFaci.id &&
          fac.prioNextStation === this.configFaci.prioNextStation
        ) {
          fac.prioNextStation = this.configFaci.id;
        }
      });
      this.configFaci = null;
      this.isNewFac = false;
      if (this.$refs.myConfigMap) {
        this.$refs.myConfigMap.initializeData();
      }
    }
  },
  watch: {
    retObj: {
      handler(newVal) {
        if (this.readyWatch) {
          this.$emit("update", {
            type: "stations",
            obj: newVal
          });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      retObj: null,
      isNewFac: false,
      readyWatch: false,
      configFaci: null,
      isChanged: false,
      openQuickUpdate: null,
      quickUpdates: {
        balancestations: {
          id: "balancestations",
          name: "Balance work",
          icon: "scale-balance",
          component: () =>
            import(
              "@/components/lean/Play/Menus/Improvements/LeanImprovementBalance.vue"
            )
        },
        smed: {
          id: "smed",
          name: "Changeovers",
          icon: "reload",
          component: () =>
            import(
              "@/components/lean/Play/Menus/Improvements/LeanImprovementChangeover.vue"
            )
        },
        kanban: {
          id: "kanban",
          name: "Kanban",
          icon: "animation",
          component: () =>
            import(
              "@/components/lean/Play/Menus/Improvements/LeanImprovementKanban.vue"
            )
        },
        autoinspect: {
          id: "autoinspect",
          name: "Quality Control",
          icon: "magnify",
          component: () =>
            import(
              "@/components/lean/Play/Menus/Improvements/LeanImprovementJidoka.vue"
            )
        },
        startingtock: {
          id: "startingtock",
          name: "Starting Stocks",
          icon: "cube-outline",
          component: () =>
            import("@/components/lean/Create/LeanConfigStartingStock.vue")
        }
      }
    };
  }
};
</script>

<style lang="scss">
.custo-leanstations-group {
  input[type="number"] {
    min-width: 60px;
  }
}
</style>
