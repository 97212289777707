<template>
  <div class="custo-leanparams-group" v-if="retObj">
    <div class="bg-transit-slide" :class="{ 'bg-transit-slide-open': recap }">
      <div class="columns mb-0">
        <!-- <p class="column pb-0 is-3">
          Number of rounds :
          <b>{{ retObj.runs.length }}</b>
        </p> -->
        <p class="column pb-0 is-3">
          Scenario Currency :
          <b>{{ retObj.currency }}</b>
        </p>
        <p class="column pb-0">
          Moving time between cells :
          <b
            >{{ retObj.moveCellTime / 1000 }} second{{
              retObj.moveCellTime >= 2000 ? "s" : ""
            }}</b
          >
        </p>
      </div>
      <div class="columns mb-0">
        <div class="column pb-0">
          Allow Computer Player :
          <b>{{ retObj.allowComputer ? "Yes" : "No" }}</b>
        </div>
      </div>
      <div>
        Improvements available :
        <span
          class="is-size-7"
          v-for="(imp, ind) in availImprovements"
          :key="`imp-${imp.id}`"
          ><span v-if="ind > 0">, </span><b>{{ imp.name }}</b></span
        >
        <b v-if="!availImprovements || !availImprovements.length">none</b>
      </div>
    </div>
    <div
      class="bg-transit-slide bg-slide-large"
      :class="{ 'bg-transit-slide-open pb-3': !recap }"
    >
      <div class="columns mb-0">
        <b-field class="column is-3 mb-0 pb-0" label="Currency :">
          <b-select v-model="retObj.currency" placeholder="Currency">
            <option
              v-for="option in ['$', '€', '£']"
              :value="option"
              :key="option"
            >
              {{ option }}
            </option>
          </b-select>
        </b-field>
        <b-field class="column mb-0 pb-0">
          <template slot="label">
            Moving time between cells :
            <em class="has-text-grey has-text-weight-normal"
              >{{ retObj.moveCellTime / 1000 }} second{{
                retObj.moveCellTime >= 2000 ? "s" : ""
              }}</em
            >
          </template>
          <b-field class="pr-6">
            <b-slider
              class="mb-6 pt-1"
              v-model.number="retObj.moveCellTime"
              :min="0"
              :max="3000"
              :step="250"
              :ticks="true"
              lazy
              expanded
            >
              <template v-for="val in [0, 250, 500, 1000, 2000, 3000]">
                <b-slider-tick :value="val" :key="`mct${val}`"
                  >{{ val / 1000 }}s</b-slider-tick
                >
              </template>
            </b-slider>
          </b-field>
        </b-field>
      </div>
      <div class="mb-4">
        <b-switch v-model="retObj.allowComputer"
          ><b>Allow Computer Player</b>
          <em class="has-text-grey"
            >- in case a participant is missing</em
          ></b-switch
        >
      </div>
      <div class="box-divider mt-0"></div>
      <span class="label">Improvements available for participants :</span>
      <ul class="bg-multicol pb-6 pl-1">
        <li v-for="imp in allImprovements" :key="imp.id" class="py-2 pr-3">
          <b-field>
            <b-switch v-model="imp.include">{{ imp.name }}</b-switch>
          </b-field>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";

export default {
  name: "LeanConfigParams",
  props: {
    game: Object,
    recap: Boolean
  },
  computed: {
    availImprovements() {
      return this.allImprovements.filter(imp => imp.include);
    }
  },
  created() {
    this.initializeData();
  },
  methods: {
    sendUpdate() {
      this.$emit("sendvalidate");
    },
    initializeData() {
      setTimeout(() => {
        this.readyWatch = true;
      }, 20);
      if (this.game) {
        this.retObj = { ...this.game.params };
        if (!this.retObj.improvements) {
          this.retObj.improvements = {};
        }
        this.allImprovements.forEach(imp => {
          if (this.retObj.improvements[imp.id]) {
            imp.include = true;
          }
        });
      }
    }
  },
  watch: {
    // game: {
    //   handler() {
    //     this.retObj = { ...this.game.params };
    //   },
    //   deep: true
    // },
    allImprovements: {
      handler(newVal) {
        newVal.forEach(imp => {
          if (imp.include) {
            this.retObj.improvements[imp.id] = true;
          } else {
            this.retObj.improvements[imp.id] = false;
          }
        });
      },
      deep: true
    },
    retObj: {
      handler(newVal) {
        if (this.readyWatch) {
          let filterParams = { ...newVal };
          delete filterParams["mapSize"];
          delete filterParams["productsTemplates"];
          delete filterParams["consumerDemand"];
          delete filterParams["consumerDemandParams"];
          delete filterParams["configMessages"];
          delete filterParams["costTypes"];
          this.$emit("update", {
            type: "params",
            obj: filterParams
          });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      retObj: null,
      readyWatch: false,
      allImprovements: [
        {
          id: "movestations",
          name: "Move stations",
          include: false
        },
        {
          id: "batch",
          name: "Reduce batch size",
          include: false
        },
        {
          id: "balancestations",
          name: "Balance workload",
          include: false
        },
        {
          id: "autoinspect",
          name: "Auto-inspect work (Jidoka)",
          include: false
        },
        {
          id: "smed",
          name: "Reduce tool-changeover time (SMED)",
          include: false
        },
        {
          id: "heijunka",
          name: "Level customer demand ",
          include: false
        },
        {
          id: "pokayoke",
          name: "Add click-guides (poka-yoke)",
          include: false
        },
        {
          id: "kanban",
          name: "Kanban (pull-system)",
          include: false
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.custo-leanparams-group {
  input[type="number"] {
    min-width: 60px;
  }
  .bg-multicol {
    column-count: 2;
    li {
      display: inline-block;
      width: 100%;
    }
  }
}
</style>
