<template>
  <div class="lean-config-demand-wrapper mb-0 py-3">
    <div class="bg-transit-slide" :class="{ 'bg-transit-slide-open': !recap }">
      <div class="columns mb-0">
        <div class="column is-4">
          <b-field label="Total Game Duration :">
            <b-field grouped position="is-centered">
              <b-input
                v-model.number="demandParams.totalDuration"
                type="number"
                :min="2"
                :max="60"
                :disabled="improve"
                required
              ></b-input>
              <p class="control has-text-grey pt-2">
                minutes
              </p>
            </b-field>
          </b-field>
        </div>
        <div class="column">
          <b-field>
            <b-checkbox v-model="demandParams.visibility"
              >Full visibility of the customer orders from game
              start</b-checkbox
            >
          </b-field>
          <b-field>
            <b-checkbox v-model="demandParams.firstonly"
              >Only allow the fulfillment of orders in sequence (FIFO)
              :</b-checkbox
            >
          </b-field>
        </div>
      </div>
      <div class="is-divider mt-0 mb-5 mx-5"></div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-7 pt-0">
        <div
          class="bg-transit-slide"
          :class="{ 'bg-transit-slide-open': recap }"
        >
          <p>
            Game Duration: <b>{{ demandParams.totalDuration }} minutes</b>
          </p>
          <p>
            Order Frequency:
            <b>{{ (demandParams.orderFreqSec * 1000) | minsec }}</b>
          </p>
          <p>
            Time to fulfill each order:
            <b>{{ (demandParams.objLTSec * 1000) | minsec }}</b>
          </p>
          <p
            class="mt-2"
            v-for="temp in demandParams.tempData"
            :key="`tempinfo-${temp.template_id}`"
            style="word-break: break-all;"
          >
            {{
              game.params.productsTemplates[temp.template_id]
                ? game.params.productsTemplates[temp.template_id].name
                : temp.template_id
            }}
            Total Demand:
            <b class="is-size-7">{{ temp.tot }} pcs</b>
          </p>
          <p class="mt-2" v-if="!improve">
            Visibility of all the orders from game start:
            <b>{{ demandParams.visibility ? "Yes" : "No" }}</b>
          </p>
          <p class="mt-0" v-if="!improve">
            Orders must be fulfilled in sequence (FIFO) :
            <b>{{ demandParams.firstonly ? "Yes" : "No" }}</b>
          </p>
        </div>
        <b-tabs
          v-if="!recap"
          expanded
          position="is-centered"
          :animated="false"
          v-model="demandUpdateTab"
        >
          <b-tab-item
            label="Manual Update"
            class="is-size-7"
            :disabled="improve"
          >
            <div class="has-text-centered is-size-7 py-2">
              <em>Detail of customer orders:</em>
            </div>
            <b-table
              class="lean-manu-demand-table"
              :data="manuDemand"
              :mobile-cards="false"
              narrowed
            >
              <b-table-column v-slot="props" custom-key="delete">
                <a @click="manuDemand.splice(props.index, 1)" class="pt-1">
                  <b-icon icon="delete" class="has-text-danger"></b-icon>
                </a>
              </b-table-column>
              <b-table-column v-slot="props" label="Order:">
                <div>
                  <em class="has-text-grey is-size-6"
                    >#{{ props.index + 1 }}</em
                  >
                </div>
              </b-table-column>
              <b-table-column v-slot="props" label="Time created:">
                <b-field grouped>
                  <p class="control has-text-grey pt-2 is-size-7">
                    Min
                  </p>
                  <b-input
                    type="number"
                    size="is-small"
                    min="0"
                    step="0.001"
                    max="60"
                    v-model.number="props.row.created_min"
                  ></b-input>
                </b-field>
              </b-table-column>
              <b-table-column v-slot="props" label="Time to fulfill:">
                <b-field grouped>
                  <b-input
                    type="number"
                    size="is-small"
                    min="1"
                    step="0.5"
                    max="30"
                    v-model.number="props.row.obj_lead_time"
                  ></b-input>
                  <p class="control has-text-grey pt-2 is-size-7">
                    min
                  </p>
                </b-field>
              </b-table-column>
              <b-table-column v-slot="props" label="Product:" width="90px">
                <b-field
                  :type="{
                    'is-danger': !game.params.productsTemplates[
                      props.row.template_id
                    ]
                  }"
                  :message="
                    !game.params.productsTemplates[props.row.template_id]
                      ? 'Unknown template ID'
                      : ''
                  "
                  class="pr-4"
                >
                  <b-input
                    type="text"
                    size="is-small"
                    maxlength="2"
                    :has-counter="false"
                    v-model="props.row.template_id"
                    :pattern="
                      Object.keys(game.params.productsTemplates).join('|')
                    "
                  ></b-input>
                </b-field>
              </b-table-column>
              <b-table-column v-slot="props" label="Quantity:">
                <b-field>
                  <b-input
                    type="number"
                    size="is-small"
                    min="1"
                    max="999"
                    v-model.number="props.row.qty"
                  ></b-input>
                </b-field>
              </b-table-column>
            </b-table>
            <div class="has-text-centered">
              <b-button type="is-success" @click="addOrder" rounded>
                Add order at the end
              </b-button>
            </div>
          </b-tab-item>
          <b-tab-item label="Generate New Profile">
            <div class="has-text-centered is-size-7 py-2">
              <em>New demand profile parameters:</em>
            </div>
            <div class="columns">
              <div class="column">
                <b-table
                  class="lean-manu-demand-table is-size-7"
                  :data="demandParams.tempData"
                  :mobile-cards="false"
                  narrowed
                >
                  <b-table-column v-slot="props" label="Product" width="115px">
                    <b-field
                      :type="{
                        'is-danger': !game.params.productsTemplates[
                          props.row.template_id
                        ]
                      }"
                      :message="
                        !game.params.productsTemplates[props.row.template_id]
                          ? 'Unknown template ID'
                          : ''
                      "
                      class="pr-4"
                    >
                      <b-input
                        type="text"
                        size="is-small"
                        maxlength="2"
                        :disabled="true"
                        :has-counter="false"
                        v-model="props.row.template_id"
                        :pattern="
                          Object.keys(game.params.productsTemplates).join('|')
                        "
                      ></b-input>
                    </b-field>
                  </b-table-column>
                  <b-table-column v-slot="props" label="Total Qty">
                    <b-field>
                      <b-input
                        type="number"
                        size="is-small"
                        min="0"
                        max="999"
                        :disabled="improve"
                        v-model.number="props.row.tot"
                      ></b-input>
                    </b-field>
                  </b-table-column>
                  <b-table-column v-slot="props" label="Variability">
                    <b-field>
                      <b-input
                        type="number"
                        size="is-small"
                        min="0"
                        max="99"
                        v-model.number="props.row.var"
                        :disabled="demandParams.levelQty || improve"
                      ></b-input>
                    </b-field>
                  </b-table-column>
                </b-table>
              </div>
              <div class="column">
                <p>
                  <b-checkbox v-model="demandParams.levelMix" class="pt-2 pb-5"
                    ><b>Level Product Mix</b></b-checkbox
                  >
                </p>
                <b-field grouped>
                  <b-checkbox v-model="demandParams.levelQty" class="pt-2 pb-5"
                    ><b>Level Quantity</b></b-checkbox
                  >
                  <b-input
                    class="pl-1"
                    v-if="demandParams.levelQty"
                    type="number"
                    :max="99"
                    :min="1"
                    v-model.number="demandParams.levelQtyPcs"
                  ></b-input>
                  <p
                    v-if="demandParams.levelQty"
                    class="control pt-2 has-text-grey"
                  >
                    pcs
                  </p>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Frequency of orders :">
                  <b-field grouped position="is-centered">
                    <p class="control has-text-grey pt-2">Every</p>
                    <b-input
                      v-model.number="demandParams.orderFreqSec"
                      type="number"
                      :min="5"
                      :max="300"
                      :disabled="demandParams.levelQty || improve"
                      required
                    ></b-input>
                    <p class="control has-text-grey pt-2">
                      sec ({{ (demandParams.orderFreqSec * 1000) | minsec }})
                    </p>
                  </b-field>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Time to fulfill each order :">
                  <b-field grouped position="is-centered">
                    <b-input
                      v-model.number="demandParams.objLTSec"
                      type="number"
                      :min="10"
                      :max="demandParams.totalDuration * 60"
                      :disabled="improve"
                      required
                    ></b-input>
                    <p class="control has-text-grey pt-2">
                      sec ({{ (demandParams.objLTSec * 1000) | minsec }})
                    </p>
                  </b-field>
                </b-field>
              </div>
            </div>
            <div class="buttons is-centered">
              <b-button
                size="is-medium"
                type="is-info"
                rounded
                @click="generateProfile"
                >Generate new demand profile</b-button
              >
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
      <div class="column is-5 pt-0">
        <lean-demand-chart
          :my-style="{ height: recap ? '220px' : '300px' }"
          ref="myDemandChart"
          :manu-demand="manuDemand"
        ></lean-demand-chart>
      </div>
    </div>
  </div>
</template>

<script>
// import profiles from "@/assets/demandProfiles";
import LeanDemandChart from "@/components/lean/Create/LeanDemandChartContainer.vue";

export default {
  name: "LeanConfigDemand",
  props: {
    game: Object,
    disable: Boolean,
    recap: Boolean,
    improve: Boolean
  },
  components: {
    LeanDemandChart
  },
  mounted() {
    if (
      this.improve ||
      (this.$route.params && this.$route.params.tab === "create")
    ) {
      this.demandUpdateTab = 1;
    }
    this.initializeData(true);
  },
  methods: {
    initializeData(updateTots) {
      if (!this.game) {
        return;
      }
      this.readyWatch = false;
      setTimeout(() => {
        this.readyWatch = true;
      }, 20);
      if (this.game.params.consumerDemandParams) {
        this.demandParams = {
          ...this.demandParams,
          ...this.game.params.consumerDemandParams
        };
      }
      //Update vs. product templates available
      Object.values(this.game.params.productsTemplates).forEach(pt => {
        if (
          !this.demandParams.tempData.find(
            tempdata => tempdata.template_id === pt.template_id
          )
        ) {
          this.demandParams.tempData.push({
            template_id: pt.template_id,
            tot: 0,
            var: 0
          });
        }
      });
      this.demandParams.tempData = this.demandParams.tempData.filter(
        tempdata => this.game.params.productsTemplates[tempdata.template_id]
      );
      if (
        this.game.params.consumerDemand &&
        this.game.params.consumerDemand.length
      ) {
        this.manuDemand = [
          ...this.game.params.consumerDemand.filter(
            dem => this.game.params.productsTemplates[dem.template_id]
          )
        ];
        if (updateTots) {
          this.updateGenerateDemandTots();
        }
      }
    },
    updateGenerateDemandTots() {
      let oldTempData = [...this.demandParams.tempData].sort((ta, tb) => {
        return ta.template_id.localeCompare(tb.template_id);
      });
      this.demandParams.tempData = [];
      Object.values(this.game.params.productsTemplates)
        .sort((ta, tb) => {
          return ta.template_id.localeCompare(tb.template_id);
        })
        .forEach((temp, ind) => {
          let tempList = this.game.params.consumerDemand
            .filter(dem => dem.template_id === temp.template_id)
            .map(dem => dem.qty);
          this.demandParams.tempData.push({
            template_id: temp.template_id,
            tot:
              tempList && tempList.length
                ? tempList.reduce((a, b) => {
                    return Number(a) + Number(b);
                  })
                : 0,
            var: oldTempData[ind]
              ? oldTempData[ind].var
              : tempList && tempList.length
              ? Math.round(
                  Math.sqrt(
                    tempList.reduce(function(sq, n) {
                      return (
                        sq +
                        Math.pow(
                          n -
                            tempList.reduce((a, b) => {
                              return Number(a) + Number(b);
                            }) /
                              tempList.length,
                          2
                        )
                      );
                    }, 0) /
                      (tempList.length - 1)
                  )
                )
              : 0
          });
        });
    },
    addOrder() {
      let lastOrder = this.manuDemand[this.manuDemand.length - 1];
      let nextCreaMin =
        lastOrder.created_min +
        Math.round((1000 * this.demandParams.orderFreqSec) / 60) / 1000;
      // If too many orders, change the frequency of orders to allow the new one to fit in
      if (
        nextCreaMin + lastOrder.obj_lead_time >
        this.demandParams.totalDuration
      ) {
        this.demandParams.orderFreqSec =
          (60 * (this.demandParams.totalDuration - lastOrder.obj_lead_time)) /
          this.manuDemand.length;
        this.manuDemand.forEach((order, ind) => {
          order.created_min =
            Math.round((1000 * ind * this.demandParams.orderFreqSec) / 60) /
            1000;
        });
      }
      this.manuDemand.push({
        ...lastOrder,
        created_min: this.demandParams.totalDuration - lastOrder.obj_lead_time
      });
    },
    generateProfile() {
      let totalDemand = 0;
      let sumTempSoFar = {};
      this.demandParams.tempData.forEach(temp => {
        sumTempSoFar[temp.template_id] = 0;
        totalDemand += temp.tot;
        if (this.demandParams.levelQty) {
          temp.var = 0;
        }
      });
      if (!totalDemand || !this.demandParams.orderFreqSec) {
        return;
      }
      // If demand levelling:
      if (this.demandParams.levelQty && this.demandParams.levelQtyPcs) {
        let newFreqSec =
          Math.floor(
            (10 *
              (this.demandParams.levelQtyPcs *
                (60 * this.demandParams.totalDuration -
                  this.demandParams.objLTSec))) /
              totalDemand
          ) / 10;
        if (
          newFreqSec >= 1 &&
          Math.abs(newFreqSec - this.demandParams.orderFreqSec) >= 2
        ) {
          this.demandParams.orderFreqSec = newFreqSec;
        }
      }
      let totTicks =
        1 +
        Math.max(
          0,
          Math.floor(
            (60 * this.demandParams.totalDuration -
              this.demandParams.objLTSec) /
              this.demandParams.orderFreqSec
          )
        );
      let ticksSoFar = 0;
      let newOrdersList = [];
      this.demandParams.tempData.forEach((temp, tempind) => {
        // Split the number of ticks based oneach template total demand:
        let tempTicks = Math.max(
          1,
          Math.round((totTicks * temp.tot) / totalDemand)
        );
        // Don't add more ticks than the remining number of templates
        if (
          totTicks - (ticksSoFar + tempTicks) <
          this.demandParams.tempData.length - (tempind + 1)
        ) {
          tempTicks -= Math.min(
            tempTicks - 1,
            this.demandParams.tempData.length - (tempind + 1)
          );
        }
        //If last temp, add the remaining qty of ticks needed
        if (tempind >= this.demandParams.tempData.length - 1) {
          tempTicks = totTicks - ticksSoFar;
        }
        ticksSoFar += tempTicks;
        if (tempTicks) {
          for (let ii = 0; ii < tempTicks; ii++) {
            let qty = 0;
            if (
              ii >= tempTicks - 1 &&
              sumTempSoFar[temp.template_id] < temp.tot
            ) {
              //If last temp tick, add the remaining qty
              qty = temp.tot - sumTempSoFar[temp.template_id];
            } else {
              // Otherwise take the average and add a little variability
              qty = Math.max(
                0,
                Math.min(
                  temp.tot - sumTempSoFar[temp.template_id],
                  Math.round(
                    temp.tot / tempTicks +
                      Math.max(
                        0,
                        (Math.random() < 0.5 ? -1 : 1) *
                          Math.random() *
                          temp.var
                      )
                  )
                )
              );
            }
            sumTempSoFar[temp.template_id] += qty;
            if (qty) {
              newOrdersList.push({
                created_min: null,
                obj_lead_time:
                  Math.round((2 * this.demandParams.objLTSec) / 60) / 2,
                fulfilled_at: null,
                template_id: temp.template_id,
                qty: qty,
                temp_rank: (ii * totalDemand) / temp.tot
              });
            }
          }
        }
      });
      // Mix product types if needed :
      if (this.demandParams.levelMix) {
        newOrdersList.sort((oa, ob) => {
          return oa.temp_rank - ob.temp_rank;
        });
      }
      // Finally setup the creation time incrementally
      let incrTime = 0;
      newOrdersList.forEach(order => {
        order.created_min = Math.round((1000 * incrTime) / 60) / 1000;
        incrTime += this.demandParams.orderFreqSec;
      });
      this.manuDemand = [...newOrdersList];
    }
  },
  watch: {
    manuDemand: {
      handler(newVal) {
        if (this.readyWatch) {
          this.readyWatch = false;
          this.$emit("update", {
            type: "demand",
            obj: { tab: newVal, params: this.demandParams }
          });
          this.updateGenerateDemandTots();
          setTimeout(() => {
            this.readyWatch = true;
          }, 20);
        }
      },
      deep: true
    },
    demandParams: {
      handler(newVal) {
        if (this.readyWatch) {
          this.$emit("update", {
            type: "demandparams",
            obj: newVal
          });
        }
      },
      deep: true
    },
    game: {
      handler() {
        if (this.readyWatch) {
          this.initializeData(false);
        }
      },
      deep: true
    }
  },
  data() {
    return {
      demandUpdateTab: 0,
      demandParams: {
        totalDuration: 5,
        firstonly: true,
        visibility: false,
        orderFreqSec: 60,
        objLTSec: 120,
        levelMix: false,
        levelQty: false,
        levelQtyPcs: 2,
        tempData: []
      },
      manuDemand: [],
      readyWatch: false,
      generateDemand: []
    };
  }
};
</script>

<style lang="scss">
.lean-config-demand-wrapper {
  .lean-manu-demand-table {
    max-height: 400px;
    overflow: scroll;
    td,
    th {
      border-right: 1px solid lightgrey;
    }
  }

  .has-text-centered .field.is-grouped {
    justify-content: center;
  }
  .b-tabs .tab-content {
    padding: 0em 0;
  }
  input[type="number"] {
    min-width: 60px;
  }
}
</style>
