<template>
  <div class="lean-edit-faci-wrapper">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered">Edit {{ retObj.name }}</p>
      <button
        class="delete"
        aria-label="close"
        @click="$parent.close()"
      ></button>
    </header>
    <section class="modal-card-body py-2">
      <form
        ref="leanfaciform"
        id="leanfaciform"
        v-if="retObj"
        @submit.prevent="checkForm"
      >
        <div class="columns mb-0">
          <div class="column">
            <b-field label="Name :">
              <b-input v-model="retObj.name" type="text" required></b-input>
            </b-field>
            <b-field label="Player Name :" v-if="isLive">
              <b-input v-model="retObj.playerName" type="text"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Logo :">
              <div class="block">
                <b-radio
                  v-for="ico in iconsList"
                  :key="ico"
                  v-model="retObj.icon"
                  :native-value="ico"
                >
                  <b-icon :icon="ico"></b-icon>
                </b-radio>
              </div>
            </b-field>
          </div>
        </div>
        <div class="columns mb-0">
          <b-field class="column is-half" label="Insert in the flow before :">
            <b-select
              placeholder="Select a Station"
              v-model="retObj.prioNextStation"
              expanded
              required
            >
              <option
                v-for="option in allStations"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
                #{{ option.id }}
              </option>
            </b-select>
          </b-field>
          <b-field class="column is-half" label="Kanban max capacity (pcs) :">
            <b-field grouped>
              <b-switch
                v-model="toggleKanbanFac"
                class="pt-2"
                size="is-medium"
              ></b-switch>
              <b-slider
                v-if="toggleKanbanFac"
                v-model.number="retObj.params.kanbanCapa"
                :min="1"
                :max="10"
                expanded
                :step="1"
                :ticks="true"
              >
                <template v-for="val in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
                  <b-slider-tick :value="val" v-bind:key="`val${val}`">{{
                    val
                  }}</b-slider-tick>
                </template>
              </b-slider>
            </b-field>
          </b-field>
        </div>
        <div class="columns mb-0" v-if="retObj.type === 'painting'">
          <b-field class="column is-half" label="Colors available :">
            <b-field>
              <b-checkbox-button
                v-model="retObj.params.availColors"
                v-for="col in Object.values(leanParams.availColors)"
                :key="`col-${col.id}`"
                :native-value="col.id"
                :type="col.class"
              >
                {{ col.colorName }}
              </b-checkbox-button>
            </b-field>
          </b-field>
          <b-field class="column is-half" label="Shapes available :">
            <b-field>
              <b-checkbox-button
                v-model="retObj.params.availShapes"
                v-for="sha in Object.values(leanParams.availShapes)"
                :key="`sha-${sha.id}`"
                :native-value="sha.id"
                type="is-info is-light"
              >
                <b-icon :icon="sha.icon"></b-icon>
                <span class="ml-2">{{ sha.shape | capitalize }}</span>
              </b-checkbox-button>
            </b-field>
          </b-field>
        </div>
        <div class="columns mb-0" v-if="retObj.type === 'painting'">
          <b-field
            class="column is-half"
            label="Can remove dots (Quality control) :"
          >
            <b-switch
              v-model="retObj.params.stampRemove"
              class="pt-2"
              size="is-medium"
            ></b-switch>
          </b-field>
          <b-field class="column is-half" label="Tool Changeover duration :">
            <b-select
              placeholder="Select a duration"
              v-model="retObj.params.changeoverDuration"
            >
              <option
                v-for="milli in changeoverTimesAvail"
                :value="milli"
                :key="milli"
              >
                {{ milli / 1000 }} sec
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="columns mb-0 is-centered is-multilined">
          <div class="column">
            <b-field label="Starting Stock :" grouped>
              <b-field
                v-for="pt in Object.values(game.params.productsTemplates).sort(
                  (a, b) => {
                    return a.template_id.localeCompare(b.template_id);
                  }
                )"
                :key="`sst-${pt.template_id}`"
                grouped
                class="mr-5"
              >
                <p class="control pt-3  ">
                  <b>{{ pt.name }}:</b>
                </p>
                <b-input
                  v-model.number="retObj.params.startingStock[pt.template_id]"
                  :min="0"
                  :max="99"
                  class="pt-2"
                  type="number"
                  @input="updateStartingStock"
                ></b-input>
              </b-field>
            </b-field>
          </div>
        </div>
      </form>
    </section>
    <footer class="modal-card-foot buttons is-centered" v-if="isChanged">
      <b-button rounded type="is-grey" size="is-medium" @click="cancelForm"
        >Cancel</b-button
      >
      <b-button
        rounded
        form="leanfaciform"
        type="is-success"
        tag="input"
        native-type="submit"
        size="is-medium"
        :value="isNew ? 'Add station' : 'Update station'"
      ></b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LeanEditFaci",
  computed: {
    ...mapGetters(["leanParams"]),
    allStations() {
      return Object.values(this.game.facilities).filter(
        fac => fac.id !== this.value.id
      );
    }
  },
  props: {
    isNew: Boolean,
    value: Object,
    game: Object,
    isLive: Boolean
  },
  created() {
    this.initializeData();
  },
  methods: {
    checkForm(ev) {
      ev.preventDefault();
      let isValid = true;
      if (this.$refs.leanfaciform) {
        isValid = this.$refs.leanfaciform.checkValidity();
      }
      if (isValid) {
        this.$emit("input", this.retObj);
        this.$emit("sendvalidate");
      }
      return false;
    },
    cancelForm() {
      this.isChanged = false;
      this.initializeData();
      this.$parent.close();
    },
    initializeData() {
      this.readyWatch = false;
      if (this.isNew) {
        this.isChanged = true;
      }
      if (this.value) {
        this.retObj = JSON.parse(JSON.stringify(this.value));
      }
      this.retObj.params.startingStock = {
        ...this.retObj.params.startingStock
      };
      if (this.retObj.params.kanbanCapa) {
        this.toggleKanbanFac = true;
      }
      setTimeout(() => {
        this.readyWatch = true;
      }, 5);
    },
    updateStartingStock() {
      this.isChanged = true;
      this.$emit("updatestartingstock");
    }
  },
  watch: {
    retObj: {
      handler() {
        if (this.readyWatch) {
          this.isChanged = true;
        }
      },
      deep: true
    },
    toggleKanbanFac() {
      if (this.readyWatch) {
        if (this.toggleKanbanFac) {
          this.retObj.params.kanbanCapa = 1;
        } else {
          this.retObj.params.kanbanCapa = null;
        }
      }
    }
  },
  data() {
    return {
      isChanged: false,
      retObj: null,
      readyWatch: false,
      toggleKanbanFac: false,
      changeoverTimesAvail: [0, 100, 500, 1000, 2000, 3000, 5000, 10000],
      iconsList: [
        "radiobox-marked",
        "cart",
        "call-split",
        "airplane",
        "home",
        "briefcase",
        "web",
        "truck",
        "telescope",
        "headphones",
        "rocket",
        "glass-mug",
        "pill",
        "account-box",
        "laptop",
        "copyright",
        "animation",
        "cog",
        "cellphone",
        "car"
      ]
    };
  }
};
</script>

<style lang="scss">
.lean-edit-faci-wrapper {
  input[type="number"] {
    min-width: 60px;
  }
}
</style>
