<template>
  <div class="config-lean-wrapper">
    <div v-if="params && facilities">
      <div class="box">
        <!-- General config -->
        <div class="columns mb-0">
          <div class="column pb-0">
            <h2 class="title is-5">
              General
            </h2>
          </div>
          <div class="column is-narrow buttons pb-0" v-if="canEdit">
            <b-button
              class="is-pulled-right has-text-weight-bold"
              :type="
                updatesObj['general'] ? 'is-success is-light' : 'is-warning'
              "
              :icon-left="updatesObj['general'] ? 'chevron-up' : 'pencil'"
              size="is-small"
              @click="updatesObj['general'] = !updatesObj['general']"
              >{{ updatesObj["general"] ? "OK" : "Edit" }}</b-button
            >
          </div>
        </div>
        <lean-config-params
          :game="{ params, facilities }"
          ref="myConfigParams"
          :my-style="{
            'max-height': updatesObj['general'] ? '260px' : '100px'
          }"
          :disable="false"
          :recap="!updatesObj['general']"
          @update="updateLocalGame"
        ></lean-config-params>
        <div
          class="bg-transit-slide"
          :class="{
            'bg-transit-slide-open':
              updatesObj['general'] || updatesObj['products'],
            'box-divider-greylane':
              updatesObj['general'] || updatesObj['products']
          }"
        ></div>
        <div class="box-divider"></div>
        <!-- Product Templates -->
        <div class="columns mb-0">
          <div class="column">
            <h2 class="title is-5">
              Product templates
            </h2>
          </div>
          <div class="column is-narrow buttons pb-0" v-if="canEdit">
            <b-button
              class="has-text-weight-bold"
              :type="
                updatesObj['products'] ? 'is-success is-light' : 'is-warning'
              "
              :icon-left="updatesObj['products'] ? 'chevron-up' : 'pencil'"
              size="is-small"
              @click="updatesObj['products'] = !updatesObj['products']"
              >{{ updatesObj["products"] ? "OK" : "Edit" }}</b-button
            >
          </div>
        </div>
        <lean-config-products
          :game="{ params, facilities }"
          ref="myConfigProducts"
          :disable="false"
          :recap="!updatesObj['products']"
          @update="updateLocalGame"
        ></lean-config-products>
        <div
          class="bg-transit-slide"
          :class="{
            'bg-transit-slide-open':
              updatesObj['stations'] || updatesObj['products'],
            'box-divider-greylane':
              updatesObj['stations'] || updatesObj['products']
          }"
        ></div>
        <div class="box-divider"></div>
        <!-- Stations -->
        <div class="columns mb-0">
          <div class="column">
            <h2 class="title is-5">
              Workstations
            </h2>
          </div>
          <div class="column is-narrow buttons pb-0" v-if="canEdit">
            <b-button
              class="has-text-weight-bold"
              :type="
                updatesObj['stations'] ? 'is-success is-light' : 'is-warning'
              "
              :icon-left="updatesObj['stations'] ? 'chevron-up' : 'pencil'"
              size="is-small"
              @click="updatesObj['stations'] = !updatesObj['stations']"
              >{{ updatesObj["stations"] ? "OK" : "Edit" }}</b-button
            >
          </div>
        </div>
        <lean-config-stations
          :game="{ params, facilities }"
          ref="myConfigStations"
          :recap="!updatesObj['stations']"
          @update="updateLocalGame"
          @updatestartingstock="$emit('updatestartingstock')"
        ></lean-config-stations>
        <div
          class="bg-transit-slide"
          :class="{
            'bg-transit-slide-open':
              updatesObj['stations'] || updatesObj['demand'],
            'box-divider-greylane':
              updatesObj['stations'] || updatesObj['demand']
          }"
        ></div>
        <div class="box-divider"></div>
        <!-- Demand -->
        <div class="columns mb-0">
          <div class="column">
            <h2 class="title is-5">
              Customer Orders
            </h2>
          </div>
          <div class="column is-narrow buttons pb-0" v-if="canEdit">
            <b-button
              class="has-text-weight-bold"
              :type="
                updatesObj['demand'] ? 'is-success is-light' : 'is-warning'
              "
              :icon-left="updatesObj['demand'] ? 'chevron-up' : 'pencil'"
              size="is-small"
              @click="updatesObj['demand'] = !updatesObj['demand']"
              >{{ updatesObj["demand"] ? "OK" : "Edit" }}</b-button
            >
          </div>
        </div>
        <lean-config-demand
          :game="{ params, facilities }"
          ref="myConfigDemand"
          :my-style="{ 'max-height': updatesObj['demand'] ? '260px' : '100px' }"
          :disable="false"
          :recap="!updatesObj['demand']"
          @update="updateLocalGame"
        ></lean-config-demand>

        <div
          class="bg-transit-slide"
          :class="{
            'bg-transit-slide-open': updatesObj['cost'] || updatesObj['demand'],
            'box-divider-greylane': updatesObj['cost'] || updatesObj['demand']
          }"
        ></div>
        <div class="box-divider"></div>
        <!-- Costs and Turnover config -->
        <div class="columns mb-0">
          <div class="column pb-0">
            <h2 class="title is-5">
              Revenue and Cost variables
            </h2>
          </div>
          <div class="column is-narrow buttons pb-0" v-if="canEdit">
            <b-button
              class="is-pulled-right has-text-weight-bold"
              :type="updatesObj['cost'] ? 'is-success is-light' : 'is-warning'"
              :icon-left="updatesObj['cost'] ? 'chevron-up' : 'pencil'"
              size="is-small"
              @click="updatesObj['cost'] = !updatesObj['cost']"
              >{{ updatesObj["cost"] ? "OK" : "Edit" }}</b-button
            >
          </div>
        </div>
        <lean-config-costs
          :game="{ params, facilities }"
          ref="myConfigParams"
          :my-style="{
            'max-height': updatesObj['cost'] ? '260px' : '100px'
          }"
          :disable="false"
          :recap="!updatesObj['cost']"
          @update="updateLocalGame"
        ></lean-config-costs>
      </div>

      <!-- <div class="box-divider"></div> -->
    </div>
  </div>
</template>

<script>
import LeanConfigParams from "@/components/lean/Create/LeanConfigParams.vue";
import LeanConfigCosts from "@/components/lean/Create/LeanConfigCosts.vue";
import LeanConfigProducts from "@/components/lean/Create/LeanConfigProducts.vue";
import LeanConfigDemand from "@/components/lean/Create/LeanConfigDemand.vue";
import LeanConfigStations from "@/components/lean/Create/LeanConfigStations.vue";

export default {
  name: "LeanConfig",
  components: {
    LeanConfigDemand,
    LeanConfigParams,
    LeanConfigCosts,
    LeanConfigProducts,
    LeanConfigStations
  },
  props: {
    value: Object,
    canEdit: Boolean
  },
  created: function() {
    setTimeout(() => {
      this.readyWatch = true;
    }, 20);
    if (this.value.params) {
      this.params = { ...this.value.params };
    }
    if (this.value.facilities) {
      this.facilities = { ...this.value.facilities };
    }
  },
  watch: {
    params: {
      handler() {
        return this.emitUpdate();
      },
      deep: true
    },
    facilities: {
      handler() {
        return this.emitUpdate();
      },
      deep: true
    }
  },
  methods: {
    emitUpdate() {
      if (this.readyWatch) {
        this.$emit("input", {
          ...this.value,
          params: this.params,
          facilities: this.facilities
        });
      }
    },
    updateLocalGame(event) {
      if (event.type === "demandparams") {
        this.params.consumerDemandParams = event.obj;
      }
      if (event.type === "demand") {
        this.params.consumerDemand = event.obj.tab;
        this.params.consumerDemandParams = event.obj.params;
      }
      if (event.type === "params") {
        this.params = { ...this.params, ...event.obj };
      }
      if (event.type === "costs") {
        this.params.costTypes = event.obj;
      }
      if (event.type === "products") {
        this.params.productsTemplates = event.obj;
      }
      if (event.type === "mapSize") {
        this.params.mapSize = event.obj;
      }
      if (event.type === "stations") {
        this.facilities = event.obj;
      }
    }
  },
  data() {
    return {
      readyWatch: false,
      updatesObj: {
        general: false,
        cost: false,
        products: false,
        demand: false,
        stations: false,
        messages: false
      },
      params: null,
      facilities: null
    };
  }
};
</script>

<style lang="scss">
.config-lean-wrapper {
  padding: 0;
  .box {
    margin-bottom: 0.75rem;
  }
}
</style>
