<template>
  <div class="custo-leanproducts-group is-multiline">
    <div class="bg-transit-slide" :class="{ 'bg-transit-slide-open': recap }">
      <div class="columns mb-0 is-multiline">
        <div
          class="column mb-0 is-4 has-text-centered columns is-vcentered"
          v-for="prod in products"
          :key="prod.template_id"
        >
          <div class="column is-5 has-text-right pr-0">
            <b
              ><span class="has-text-grey">#{{ prod.template_id }}</span> -
              {{ prod.name }} :</b
            >
          </div>
          <div class="column my-0 pb-0">
            <component
              v-bind:is="leanProductFigures[prod.type]"
              :product="prod"
              :station="null"
              :show-guides="prod.showGuides"
              :show-finished="true"
              :is-disabled="true"
              :is-template="true"
            ></component>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-transit-slide bg-slide-large"
      :class="{ 'bg-transit-slide-open pt-1 pb-3': !recap }"
    >
      <div v-for="prod in products" :key="prod.template_id">
        <b-field grouped>
          <p class="control has-text-grey pt-2 pl-2 has-text-weight-bold">
            #{{ prod.template_id }}
          </p>
          <b-input
            type="text"
            v-model="prod.name"
            placeholder="Product Name"
          ></b-input>
          <p class="control pt-2 ml-2"><b>Batch size : </b></p>
          <b-input
            v-model.number="prod.batchSize"
            type="number"
            :max="100"
            :min="1"
          ></b-input>
          <p class="control pt-2 has-text-grey">pcs</p>
          <p class="control pt-2 ml-2"><b>Click-guides : </b></p>
          <b-switch v-model="prod.showGuides"></b-switch>
          <p class="control pt-2 ml-2"><b>Click-tolerance : </b></p>
          <b-input
            v-model.number="prod.design.targetRadius"
            type="number"
            :max="40"
            :min="1"
          ></b-input>
        </b-field>
        <div class="columns mb-0">
          <div class="column is-2 has-text-centered pb-0">
            <component
              v-bind:is="leanProductFigures[prod.type]"
              :product="prod"
              :station="null"
              :show-guides="prod.showGuides"
              :show-finished="true"
              :is-disabled="true"
              :is-template="true"
            ></component>
          </div>
          <div class="column px-3 py-2">
            <b-field
              v-for="(col, ind) in prod.design.columns"
              :key="`col-${ind}`"
              class="mb-2"
            >
              <b-tooltip
                style="cursor: help;"
                position="is-right"
                type="is-dark"
                label="Remove column"
              >
                <b-button
                  @click="ev => removeDotsColumn(prod, col)"
                  icon-left="minus"
                  type="is-primary"
                  size="is-small"
                  class="mr-2"
                ></b-button>
              </b-tooltip>
              <b-input
                size="is-small"
                class="lean-maxwidth20"
                v-model="col.label"
              ></b-input>
              <p class="control px-2 pt-1">
                <b-icon icon="arrow-right-bold"></b-icon>
              </p>
              <b-taginput
                type="is-info is-light"
                v-model="col.dots"
                :data="allDotsCombi"
                autocomplete
                :allow-new="false"
                :open-on-focus="true"
                :allow-duplicates="true"
                field="label"
                placeholder="Add a dot"
                maxtags="6"
                :has-counter="false"
                rounded
                expanded
                @input="updateProductsDots(prod)"
              >
                <template slot-scope="props"
                  ><b-icon
                    :type="leanParams.availColors[props.option.color].class"
                    :icon="props.option.shape"
                    size="is-small"
                  ></b-icon>
                  {{ props.option.label }}
                </template>
              </b-taginput>
            </b-field>
            <div class="buttons">
              <b-button
                @click="ev => addDotsColumn(prod)"
                icon-left="plus"
                type="is-success is-light"
                size="is-small"
                >Add column</b-button
              >
              <b-button
                @click="removeTemplate(prod)"
                icon-left="delete"
                type="is-danger"
                size="is-small"
                rounded
                >Remove product template</b-button
              >
            </div>
          </div>
        </div>
        <div class="box-divider mt-1 mb-2"></div>
      </div>
      <div class="buttons is-centered">
        <b-button
          @click="addTemplate()"
          rounded
          type="is-success"
          icon-left="file-plus"
          :disabled="products.length >= 6"
          >Add product template</b-button
        >
        <p class="control is-size-5 ml-3 pb-2">{{ products.length }}/6</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LeanConfigProducts",
  computed: {
    ...mapGetters(["leanProductFigures", "leanParams"]),
    retObj() {
      let obj = {};
      this.products.forEach(prod => {
        obj[prod.template_id] = prod;
      });
      return obj;
    },
    allDotsCombi() {
      let tab = [];
      Object.values(this.leanParams.availColors).forEach(col => {
        Object.values(this.leanParams.availShapes).forEach(sha => {
          tab.push({
            label: `${col.colorName} ${sha.shape}`,
            color: col.id,
            shape: sha.id
          });
        });
      });
      return tab;
    }
  },
  props: {
    game: Object,
    recap: Boolean
  },
  created() {
    this.initializeData();
  },
  methods: {
    sendUpdate() {
      this.$emit("sendvalidate");
    },
    initializeData() {
      let updateFromCol = false;
      setTimeout(() => {
        this.readyWatch = true;
      }, 20);
      if (this.game && this.game.params) {
        this.products = Object.values(this.game.params.productsTemplates).sort(
          (a, b) => {
            return a.template_id.localeCompare(b.template_id);
          }
        );
        this.products.forEach(prod => {
          prod.design.columns.forEach((col, colRank) => {
            if (col.dots && col.dots.length) {
              updateFromCol = true;
              this.updateProductsDots(prod);
            } else if (!updateFromCol) {
              col.dots = prod.operations
                .filter(ope => ope.params.position[0] === colRank)
                .map(ope => {
                  return {
                    label: `${
                      this.leanParams.availColors[ope.params.color].colorName
                    } ${this.leanParams.availShapes[ope.params.shape].shape}`,
                    color: ope.params.color,
                    shape: ope.params.shape
                  };
                });
            }
          });
        });
      }
    },
    updateProductsDots(prod) {
      let opetab = [];
      let rank = 0;
      prod.design.columns.forEach((col, colrank) => {
        col.dots.forEach((dot, dotRank) => {
          opetab.push({
            ope: "painting",
            id: "s" + rank,
            status: null,
            params: {
              shape: dot.shape,
              color: dot.color,
              position: [colrank, dotRank]
            }
          });
          rank += 1;
        });
      });
      prod.operations = opetab;
    },
    removeDotsColumn(prod, col) {
      const colRank = prod.design.columns.indexOf(col);
      if (colRank > -1) {
        prod.design.columns.splice(colRank, 1);
      }
      prod.operations = prod.operations.filter(
        ope => ope.params.position[0] !== colRank
      );
      prod.operations.forEach(ope => {
        if (ope.params.position[0] > colRank) {
          ope.params.position[0] -= 1;
        }
      });
    },
    addDotsColumn(prod) {
      prod.design.columns.push({
        label: "Col " + (prod.design.columns.length + 1),
        dots: []
      });
    },
    removeTemplate(prod) {
      const prodRank = this.products.indexOf(prod);
      if (prodRank > -1) {
        this.products.splice(prodRank, 1);
      }
    },
    addTemplate() {
      let availChar = Array(26)
        .fill()
        .map((p, idx) => idx);
      // Remove taken ranks from the tab
      this.products.forEach(prod => {
        const charAvailRank = availChar.indexOf(
          prod.template_id.charCodeAt(0) - 65
        );
        if (charAvailRank > -1) {
          availChar.splice(charAvailRank, 1);
        }
      });
      // Create new product with first rank available in the tab
      if (availChar.length && this.products.length < 6) {
        let newId = String.fromCharCode(availChar[0] + 65);
        let newProd = {
          name: "Product " + newId,
          batchSize: 2,
          template_id: newId,
          hideStatus: true,
          showGuide: false,
          type: "dotsSheet",
          design: {
            columns: [
              {
                label: "Green",
                dots: [
                  { label: "Green circle", color: "green", shape: "circle" }
                ]
              },
              {
                label: "Red",
                dots: [
                  { label: "Red circle", color: "red", shape: "circle" },
                  { label: "Red square", color: "red", shape: "square" }
                ]
              }
            ],
            targetRadius: 4,
            addTraces: []
          },
          operations: []
        };
        this.products.push(newProd);
        this.updateProductsDots(newProd);
      }
    }
  },
  watch: {
    retObj: {
      handler(newVal) {
        if (this.readyWatch) {
          this.$emit("update", {
            type: "products",
            obj: newVal
          });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      products: [],
      readyWatch: false
    };
  }
};
</script>

<style lang="scss">
.custo-leanproducts-group {
  input[type="number"] {
    min-width: 60px;
  }
  .lean-maxwidth20 {
    max-width: 100px;
  }
}
</style>
