<template>
  <svg
    v-if="game"
    class="lean-map-svg-arrows-wrapper is-hidden-mobile"
    :viewBox="`0 0 ${canvasSize[0]} ${canvasSize[1]}`"
    preserveAspectRatio="none"
  >
    <g
      v-for="flowLine in shopFlowLines"
      :key="flowLine.id"
      style="opacity: 0.6"
    >
      {{ flowLine.textx }}
      <marker
        id="arrowhead"
        orient="auto"
        markerWidth="2"
        markerHeight="4"
        refX="0.1"
        refY="2"
      >
        <path d="M0,0 V4 L2,2 Z" fill="#0086e3" />
      </marker>
      <text>
        <textPath
          v-for="(af, ai) in arrowsFrom[flowLine.faciFrom]"
          :href="`#path-${flowLine.id}`"
          :startOffset="`${af.pos}%`"
          :key="`ah-${ai}`"
          style="font-size:5em; opacity:1; fill: #0086e3; dominant-baseline: central"
        >
          <tspan dy="-3" dx="1">➤</tspan>
        </textPath>
      </text>
      <path
        style="stroke:#0086e3;stroke-width:10;opacity:1"
        :id="`path-${flowLine.id}`"
        :d="`M${flowLine.x1} ${flowLine.y1} L${flowLine.x2} ${flowLine.y2}`"
      />
      <!-- <polyline
        :points="
          `${flowLine.x1},${flowLine.y1} ${flowLine.textx},${flowLine.texty} ${flowLine.x2},${flowLine.y2}`
        "
        style="stroke:#0086e3;stroke-width:13;opacity:0.4"
        marker-mid="url(#arrowhead)"
      /> -->
      <text
        :x="flowLine.textx"
        :y="flowLine.texty"
        font-size="42"
        text-anchor="middle"
        font-weight="bold"
        style="transform: translateY(-5px);"
        :style="
          `fill:#003C66; transition: all 300ms; opacity: ${
            hoverFaci === flowLine.faciFrom || hoverFaci === flowLine.faciTo
              ? 1
              : 0
          }`
        "
      >
        {{ flowLine.timeText }}
      </text>
    </g>
  </svg>
</template>
<script>
import { mapGetters } from "vuex";
import anime from "animejs";

export default {
  name: "LeanMapSvgArrows",
  props: { hoverFaci: String, game: Object },
  computed: {
    ...mapGetters(["movementDurations", "movingArrows"]),
    shopFlowLines() {
      if (!this.game || !this.game.params.mapSize || !this.game.facilities) {
        return [];
      }
      let tab = [];
      const halfCellx =
        (0.5 * this.canvasSize[0]) / this.game.params.mapSize[0];
      const halfCelly =
        (0.5 * this.canvasSize[1]) / this.game.params.mapSize[1];
      Object.values(this.game.facilities).forEach(faci => {
        if (
          faci.prioNextStation &&
          this.game.facilities[faci.prioNextStation]
        ) {
          let lineCoord = {
            x1:
              (faci.position[0] * this.canvasSize[0]) /
                this.game.params.mapSize[0] +
              halfCellx,
            y1:
              (faci.position[1] * this.canvasSize[1]) /
                this.game.params.mapSize[1] +
              halfCelly,
            x2:
              (this.game.facilities[faci.prioNextStation].position[0] *
                this.canvasSize[0]) /
                this.game.params.mapSize[0] +
              halfCellx,
            y2:
              (this.game.facilities[faci.prioNextStation].position[1] *
                this.canvasSize[1]) /
                this.game.params.mapSize[1] +
              halfCelly
          };
          tab.push({
            id: `linefrom-${faci.id}`,
            timeText:
              this.movementDurations && this.movementDurations[faci.id]
                ? Math.abs(
                    this.movementDurations[faci.id].to[faci.prioNextStation] /
                      100
                  ) /
                    10 +
                  "s"
                : "",
            faciFrom: faci.id,
            faciTo: faci.prioNextStation,
            textx: lineCoord.x1 + (0.8 * (lineCoord.x2 - lineCoord.x1)) / 2,
            texty: lineCoord.y1 + (0.8 * (lineCoord.y2 - lineCoord.y1)) / 2,
            ...lineCoord
          });
        }
      });
      return tab;
    },
    arrowsFrom() {
      let obj = {};
      Object.values(this.game.facilities).forEach(faci => {
        if (this.movingArrows[faci.id] || this.movingArrows.includes("all")) {
          obj[faci.id] = this.animeArrows;
        } else {
          obj[faci.id] = [{ pos: 40 }];
        }
      });
      return obj;
    }
  },
  watch: {
    movingArrows: {
      handler(newTab) {
        if (newTab && newTab.length) {
          this.animeArrows = [
            { pos: -99 },
            { pos: -66 },
            { pos: -33 },
            { pos: 0 },
            { pos: 33 },
            { pos: 66 },
            { pos: 99 }
          ];
          anime({
            targets: this.animeArrows,
            pos(el) {
              return el.pos + 100;
            },
            round: 1,
            loop: true,
            easing: "linear",
            duration: 4000
          });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      canvasSize: [2000, 1000],
      animeArrows: []
    };
  }
};
</script>

<style lang="scss">
.lean-map-svg-arrows-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
