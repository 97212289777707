<template>
  <div class="custo-leancosts-group is-multiline">
    <div class="bg-transit-slide" :class="{ 'bg-transit-slide-open': recap }">
      <div class="columns mb-0">
        <div
          class="column content mb-0"
          v-for="costGroup in costTypesGroups"
          :key="costGroup.label"
        >
          <p class="label mb-1">{{ costGroup.label }}</p>
          <ul class="is-size-7 mt-0">
            <li
              v-for="ct in costGroup.tab.filter(cti => cti.active)"
              :key="ct.name"
            >
              {{ ct.name }}:
              <b
                :class="{
                  'has-text-success': ct.isPlus,
                  'has-text-danger': !ct.isPlus
                }"
                >{{ ct.isPlus ? "+" : "-" }}{{ ct.value | currency }}</b
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="bg-transit-slide bg-slide-large"
      :class="{ 'bg-transit-slide-open pt-1 pb-3': !recap }"
    >
      <div class="columns">
        <div
          class="column"
          v-for="costGroup in costTypesGroups"
          :key="costGroup.label"
        >
          <div class="box  mx-1 py-2">
            <p class="label pb-1">{{ costGroup.label }}</p>
            <div
              v-for="(ct, ctindex) in costGroup.tab"
              :key="ct.name"
              class="mb-4"
            >
              <div v-if="ctindex > 0" class="box-divider"></div>
              <b-field class="mb-1">
                <b-checkbox v-model="ct.active">
                  {{ ct.name }}
                </b-checkbox>
              </b-field>
              <b-field
                grouped
                v-if="ct.active"
                :class="{
                  'has-text-success': ct.isPlus,
                  'has-text-danger': !ct.isPlus
                }"
              >
                <p class="control is-italic pt-2 has-text-weight-bold">
                  {{ ct.isPlus ? "+" : "-" }}{{ game.params.currency }}
                </p>
                <b-input
                  v-model.number="ct.value"
                  type="number"
                  :min="0"
                  :max="1000"
                ></b-input>
                <p class="control is-italic is-expanded pt-2">
                  {{ ct.namedetail }}
                </p>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";

export default {
  name: "LeanConfigCosts",
  computed: {
    retObj() {
      let obj = { opes: {} };
      this.costTypesGroups.forEach(ctg => {
        ctg.tab.forEach(ct => {
          if (ct.active) {
            let myVal =
              (ct.isPlus ? 1 : -1) *
              Math.min(1000, Math.max(0, Math.abs(ct.value)));
            if (ctg.isOpe) {
              obj.opes[ct.type] = myVal;
            } else {
              obj[ct.type] = myVal;
            }
          }
        });
      });
      return obj;
    }
  },
  props: {
    game: Object,
    recap: Boolean
  },
  created() {
    this.initializeData();
  },
  methods: {
    sendUpdate() {
      this.$emit("sendvalidate");
    },
    initializeData() {
      setTimeout(() => {
        this.readyWatch = true;
      }, 20);
      if (this.game) {
        this.costTypesGroups.forEach(ctg => {
          ctg.tab.forEach(ct => {
            if (this.game.params.costTypes[ct.type]) {
              ct.active = true;
              ct.value = Math.abs(this.game.params.costTypes[ct.type]);
            }
            if (this.game.params.costTypes["opes"][ct.type]) {
              ct.active = true;
              ct.value = Math.abs(this.game.params.costTypes["opes"][ct.type]);
            }
          });
        });
      }
    }
  },
  watch: {
    retObj: {
      handler(newVal) {
        if (this.readyWatch) {
          this.$emit("update", {
            type: "costs",
            obj: newVal
          });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      readyWatch: false,
      costTypesGroups: [
        {
          label: "Sales and Penalties",
          tab: [
            {
              isPlus: true,
              active: false,
              type: "sales",
              value: 100,
              name: `Unit Sales Price`,
              namedetail: "per product delivered"
            },
            {
              active: false,
              type: "latepenalty",
              value: 30,
              name: `Late Penalty`,
              namedetail: "per late product delivered"
            },
            {
              active: false,
              type: "qualitypenalty",
              value: 50,
              name: `Quality Penalty`,
              namedetail: "per deffective product delivered"
            }
          ]
        },
        {
          label: "General Costs",
          tab: [
            {
              active: false,
              type: "rawmaterial",
              value: 10,
              name: "Cost of Goods Sold",
              namedetail: "per product sold"
            },
            {
              active: false,
              type: "station",
              value: 50,
              name: "Labor cost",
              namedetail: "per station"
            },
            {
              active: false,
              type: "wip",
              value: 20,
              name: `WIP inventory cost`,
              namedetail: "per average stock"
            },
            {
              active: false,
              type: "fg",
              value: 50,
              name: `FG inventory cost`,
              namedetail: "per average stock"
            }
          ]
        },
        {
          label: "Operations Costs",
          isOpe: true,
          tab: [
            {
              active: false,
              type: "Successful",
              value: 10,
              name: `Successful operation cost`,
              namedetail: "per operation"
            },
            {
              active: false,
              type: "Failed",
              value: 10,
              name: `Failed operation cost`,
              namedetail: "per operation"
            },
            {
              active: false,
              type: "Rework",
              value: 10,
              name: `Rework operation cost`,
              namedetail: "per operation"
            }
          ]
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.custo-leancosts-group {
  input[type="number"] {
    min-width: 60px;
  }
}
</style>
