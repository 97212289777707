<template>
  <div
    class="lean-shopmap-wrapper is-relative"
    v-if="game"
    :class="{ 'in-game': inGame }"
  >
    <lean-map-svg-arrows
      :game="game"
      :hover-faci="inGame ? hoverFaci : null"
    ></lean-map-svg-arrows>
    <div
      class="columns shop-line"
      v-for="line in shopMap.lines"
      v-bind:key="line.id"
    >
      <div
        class="column shop-cell"
        :class="{ 'is-hidden-mobile': !cell.faci }"
        :id="`cell-${cell.faci ? 'faci-' + cell.faci.id : 'empty-' + cell.id}`"
        v-for="cell in line.cells"
        v-bind:key="cell.id"
      >
        <b-tooltip
          :active="
            inGame &&
              myFaci &&
              cell.faci &&
              myFaci.id === cell.faci.id &&
              game.params.game_start_at &&
              !currentProducts.length
          "
          :always="true"
          label="You are playing this station. Click to open it!"
          type="is-dark"
          position="is-bottom"
          size="is-small"
          class="is-block"
          multilined
        >
          <div
            v-if="cell.faci"
            class="card cell-station has-text-centered"
            :class="{ 'my-station': myFaci && myFaci.id === cell.faci.id }"
            @mouseover="hoverFaci = cell.faci.id"
            @mouseleave="hoverFaci = null"
          >
            <b-icon
              v-if="inGame && cell.faci.user"
              icon="account"
              :class="{
                'has-text-grey': !myFaci || myFaci.id !== cell.faci.id,
                'has-text-info': myFaci && myFaci.id === cell.faci.id
              }"
              class="tag-top-right mt-1 ml-1"
            ></b-icon>
            <!-- <b-tag
              v-if="cell.faci.totalWIP"
              class="tag-top-left mt-1"
              size="is-small"
              type="is-info is-light"
              rounded
              ><em class="has-text-weight-bold"
                >{{ cell.faci.totalWIP }}pcs</em
              ></b-tag
            > -->
            <div class="cell-station-content">
              <div
                class="cell-faci-title"
                :style="{ cursor: inGame ? 'pointer' : '' }"
                @click="clickFaci(cell.faci)"
              >
                <b-icon
                  :icon="cell.faci.icon"
                  :size="inGame ? 'is-medium' : ''"
                ></b-icon>
                <h4 class="title is-5 mb-0" :class="{ 'is-6': !inGame }">
                  {{ cell.faci.name }}
                </h4>
              </div>

              <em v-if="cell.faci.user && inGame" class="has-text-grey">
                {{
                  cell.faci.playerName
                    ? cell.faci.playerName
                    : myFaci && myFaci.id === cell.faci.id
                    ? "Add name"
                    : "Anonymous"
                }}
                <a
                  @click="$emit('editname', false)"
                  v-if="myFaci && myFaci.id === cell.faci.id"
                >
                  <b-icon size="is-small" icon="pencil"></b-icon>
                </a>
              </em>
              <em v-if="!cell.faci.user && inGame" class="has-text-grey">
                Computer
              </em>
              <div class="py-2" v-if="!inGame || !cell.faci.totalWIP"></div>
              <div
                v-if="inGame && cell.faci.totalWIP"
                class="columns faci-bot-cols has-text-grey"
              >
                <div class="column">
                  <div v-if="cell.faci.totalWIPin">
                    <svg
                      class="cell-station-fillwip"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="none"
                    >
                      <rect
                        x="0"
                        :y="100 - cell.faci.pourcWIPin"
                        width="100"
                        :height="cell.faci.pourcWIPin"
                        fill="#fff4f4"
                      />
                    </svg>
                    <span class="is-relative"
                      >WIP-in: {{ cell.faci.totalWIPin || 0 }}</span
                    >
                  </div>
                </div>
                <div class="column">
                  <div v-if="cell.faci.totalWIPout">
                    <svg
                      class="cell-station-fillwip"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="none"
                    >
                      <rect
                        x="0"
                        :y="100 - cell.faci.pourcWIPout"
                        width="100"
                        :height="cell.faci.pourcWIPout"
                        fill="#fff4f4"
                      />
                    </svg>
                    <span class="is-relative"
                      >WIP-out: {{ cell.faci.totalWIPout || 0 }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tooltip>
        <div v-if="!cell.faci" class="cell-empty"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeanMapSvgArrows from "@/components/lean/Play/LeanMapSvgArrows.vue";

export default {
  name: "LeanShopMap",
  props: {
    game: Object,
    inGame: Boolean
  },
  components: {
    LeanMapSvgArrows
  },
  computed: {
    ...mapGetters(["myFaci", "currentProducts", "leanParams"]),
    maxFaciStock() {
      let maxStock = 0;
      if (this.inGame && this.currentProducts) {
        Object.values(this.game.facilities).forEach(fac => {
          let countFac = this.currentProducts.filter(
            prod => prod.pos.fid === fac.id && !prod.fulfilled_at
          ).length;
          if (countFac > maxStock) {
            maxStock = countFac;
          }
        });
      }
      return Math.max(12, maxStock);
    },
    shopMap() {
      let obj = {
        lines: [],
        colSize: this.leanParams.sizeToCol[this.game.params.mapSize[0]]
      };
      for (let yy = 0; yy < this.game.params.mapSize[1]; yy++) {
        let line = { id: `ly-${yy}`, cells: [] };
        for (let xx = 0; xx < this.game.params.mapSize[0]; xx++) {
          line.cells.push({
            id: `lx-${xx}`,
            faci: this.searchFaciAtPos(xx, yy)
          });
        }
        obj.lines.push(line);
      }
      return obj;
    }
  },
  methods: {
    clickFaci(faci) {
      if (this.inGame) {
        this.$store.commit("setOpenFaciPanel", {
          fid: faci.id,
          gid: this.game.id
        });
        this.hoverFaci = null;
      }
    },
    searchFaciAtPos(xx, yy) {
      let retFac = null;
      Object.values(this.game.facilities).forEach(fac => {
        if (fac.position[0] === xx && fac.position[1] === yy) {
          let countStockZone = { tot: 0 };
          this.currentProducts.forEach(prod => {
            if (prod.pos.fid === fac.id && !prod.fulfilled_at) {
              if (!countStockZone[prod.pos.zone]) {
                countStockZone[prod.pos.zone] = 0;
              }
              countStockZone[prod.pos.zone] += 1;
              countStockZone.tot += 1;
            }
          });
          retFac = {
            ...fac,
            totalWIP: countStockZone.tot,
            pourcWIP: Math.round(
              (100 * countStockZone.tot) / this.maxFaciStock
            ),
            totalWIPin:
              (countStockZone["wipin"] || 0) +
              (countStockZone["workdesk"] || 0),
            pourcWIPin: Math.round(
              (100 *
                ((countStockZone["wipin"] || 0) +
                  (countStockZone["workdesk"] || 0))) /
                this.maxFaciStock
            ),
            totalWIPout: countStockZone["wipout"],
            pourcWIPout: Math.round(
              (100 * countStockZone["wipout"]) / this.maxFaciStock
            ),
            totalProc: countStockZone["workdesk"],
            pourcProc: Math.round(
              (100 * countStockZone["workdesk"]) / this.maxFaciStock
            )
          };
        }
      });
      return retFac;
    }
  },
  data() {
    return {
      hoverFaci: null
    };
  }
};
</script>

<style lang="scss">
.lean-shopmap-wrapper {
  .shop-line {
    $shop-map-border: solid 2px rgba(0, 0, 0, 0.07);
    border-top: $shop-map-border;
    &:nth-last-child(1) {
      border-bottom: $shop-map-border;
    }
    .shop-cell {
      border-left: $shop-map-border;
      padding: 0.3em;
      &:nth-last-child(1) {
        border-right: $shop-map-border;
      }
      .cell-station {
        position: relative;
        padding-top: 0.2em;
        padding-bottom: 0.5em;
        border-radius: 15px;
        overflow: hidden;
        &.my-station {
          background-color: #fffff5;
        }

        .cell-station-fillwip {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .cell-station-content {
          position: relative;
        }
        .faci-bot-cols {
          margin-top: 0.5em;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          .column {
            padding: 0.3em 0;
            position: relative;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }
  .cell-empty {
    min-height: 20px;
  }
  @media (min-width: 768px) {
    .shop-cell {
      min-height: 50px;
    }
  }
}
.lean-shopmap-wrapper.in-game {
  @media (min-width: 768px) {
    .shop-cell {
      min-height: 140px;
    }
  }
  .shop-cell {
    padding: 0.5em;
    .cell-station {
      padding: 0.75em;
    }
  }
}
</style>
