<template>
  <div class="lean-config-map-wrapper">
    <div class="has-text-centered is-italic is-size-7">
      Drag and drop to move stations.
      <span v-if="!improve">Click on an empty cell to add a station.</span>
    </div>
    <div class="grid">
      <lean-map-svg-arrows
        :game="{
          params: { mapSize: this.localMap.mapSize },
          facilities: this.localMap.facilities
        }"
      ></lean-map-svg-arrows>
      <div
        class="item"
        v-for="cell in shopCells"
        :key="cell.id"
        :style="{ width: cell.colSize }"
        :id="cell.id"
        :data-fid="cell.faci ? cell.faci.id : null"
      >
        <div class="item-content">
          <div
            v-if="!cell.faci && !improve"
            class="is-relative cell-icon-center"
            style="height: 100%;"
          >
            <a @click="$emit('addfaci', cell)">
              <b-icon
                size="is-large"
                type="is-success is-light"
                icon="plus-circle"
              ></b-icon>
            </a>
          </div>
          <div v-if="cell.faci" class="map-cell">
            <div class="card map-station has-text-centered">
              <a
                class="tag-top-left has-text-info"
                v-if="!improve"
                @click="$emit('editfaci', cell.faci)"
              >
                <b-tooltip
                  position="is-bottom"
                  type="is-dark"
                  label="Edit Parameters"
                >
                  <b-icon icon="pencil"></b-icon>
                </b-tooltip>
              </a>
              <b-icon
                icon="cursor-move"
                class="tag-top-right"
                v-if="improve"
              ></b-icon>
              <a
                class="tag-top-right has-text-danger"
                v-if="!improve && !cell.faci.mandatoryStation"
                @click="$emit('removefaci', cell.faci)"
              >
                <b-tooltip position="is-bottom" type="is-dark" label="Remove">
                  <b-icon icon="delete"></b-icon>
                </b-tooltip>
              </a>
              <h3 class="title is-6">
                <b-icon :icon="cell.faci.icon"></b-icon>
                <br />
                {{ cell.faci.name }}
                <br />
                <em class="has-text-success is-size-7">#{{ cell.faci.id }}</em>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Muuri from "muuri";
import LeanMapSvgArrows from "@/components/lean/Play/LeanMapSvgArrows.vue";

export default {
  name: "LeanConfigMap",
  components: { LeanMapSvgArrows },
  props: {
    game: Object,
    improve: Boolean
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      if (this.game) {
        this.readyWatch = false;
        // Initialize the shop cells:
        this.localMap.mapSize = this.game.params.mapSize;
        this.localMap.facilities = this.game.facilities;
        this.shopCells = [];
        for (let yy = 0; yy < this.localMap.mapSize[1]; yy++) {
          for (let xx = 0; xx < this.localMap.mapSize[0]; xx++) {
            this.shopCells.push({
              id: `cell-${xx}-${yy}`,
              xpos: xx,
              ypos: yy,
              colSize: this.sizeToWidth[this.localMap.mapSize[0]],
              faci: this.searchFaciAtPos(xx, yy)
            });
          }
        }
        if (this.muuri) {
          this.muuri.destroy();
        }
        setTimeout(() => {
          this.muuri = new Muuri(".grid", {
            dragEnabled: true,
            dragSortPredicate: {
              threshold: 50,
              action: "swap",
              migrateAction: "swap"
            }
          });
          this.muuri.on("dragReleaseEnd", () => {
            this.muuri.getItems().forEach((item, newRank) => {
              let fid = item.getElement().getAttribute("data-fid");
              if (fid) {
                let newPos = this.getPosFromRank(newRank - 1);
                let fac = this.localMap.facilities[fid];
                if (
                  newPos[0] !== fac.position[0] ||
                  newPos[1] !== fac.position[1]
                ) {
                  this.localMap.facilities[fac.id].position = [...newPos];
                }
              }
            });
          });
          setTimeout(() => {
            this.readyWatch = true;
          }, 10);
        }, 30);
      }
    },
    searchFaciAtPos(xx, yy) {
      let retFac = null;
      if (this.localMap.facilities) {
        Object.values(this.localMap.facilities).forEach(fac => {
          if (fac.position[0] === xx && fac.position[1] === yy) {
            retFac = fac;
          }
        });
      }
      return retFac;
    },
    getPosFromRank(rank) {
      let count = 0;
      if (this.localMap.mapSize) {
        for (let yy = 0; yy < this.localMap.mapSize[1]; yy++) {
          for (let xx = 0; xx < this.localMap.mapSize[0]; xx++) {
            if (count === rank) {
              return [xx, yy];
            } else {
              count += 1;
            }
          }
        }
      }
      return null;
    }
  },
  watch: {
    localMap: {
      handler: function() {
        if (this.readyWatch) {
          this.$emit("update", { type: "map", obj: this.localMap });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      shopCells: [],
      muuri: null,
      sizeToWidth: {
        1: "100%",
        2: "48%",
        3: "31.5%",
        4: "23.5%",
        5: "18.5%"
      },
      readyWatch: false,
      localMap: {
        mapSize: [5, 4],
        facilities: null
      }
    };
  }
};
</script>

<style lang="scss">
.lean-config-map-wrapper {
  .grid {
    position: relative;
    // border: solid 2px rgba(0, 0, 0, 0.07);
    .item {
      position: absolute;
      display: block;
      height: 100px;
      background-color: rgba(0, 0, 0, 0.05);
      z-index: 1;
      cursor: unset;
      margin: 2px;
      .item-content {
        position: relative;
        margin: 0;
        width: 100%;
        height: 100%;
        .map-cell {
          padding: 0.5em;
          position: relative;
          height: 100%;
          .map-station {
            cursor: grab;
            padding: 1em;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
          }
        }
      }
    }
    .item.muuri-item-dragging {
      z-index: 3;
    }
    .item.muuri-item-releasing {
      z-index: 2;
    }
    .item.muuri-item-hidden {
      z-index: 0;
    }
    .cell-icon-center .icon {
      opacity: 0;
      transition: opacity 500ms;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .cell-icon-center:hover .icon {
      opacity: 1;
    }
  }
}
</style>
