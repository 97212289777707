var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"config-lean-wrapper"},[(_vm.params && _vm.facilities)?_c('div',[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns mb-0"},[_vm._m(0),(_vm.canEdit)?_c('div',{staticClass:"column is-narrow buttons pb-0"},[_c('b-button',{staticClass:"is-pulled-right has-text-weight-bold",attrs:{"type":_vm.updatesObj['general'] ? 'is-success is-light' : 'is-warning',"icon-left":_vm.updatesObj['general'] ? 'chevron-up' : 'pencil',"size":"is-small"},on:{"click":function($event){_vm.updatesObj['general'] = !_vm.updatesObj['general']}}},[_vm._v(_vm._s(_vm.updatesObj["general"] ? "OK" : "Edit"))])],1):_vm._e()]),_c('lean-config-params',{ref:"myConfigParams",attrs:{"game":{ params: _vm.params, facilities: _vm.facilities },"my-style":{
          'max-height': _vm.updatesObj['general'] ? '260px' : '100px'
        },"disable":false,"recap":!_vm.updatesObj['general']},on:{"update":_vm.updateLocalGame}}),_c('div',{staticClass:"bg-transit-slide",class:{
          'bg-transit-slide-open':
            _vm.updatesObj['general'] || _vm.updatesObj['products'],
          'box-divider-greylane':
            _vm.updatesObj['general'] || _vm.updatesObj['products']
        }}),_c('div',{staticClass:"box-divider"}),_c('div',{staticClass:"columns mb-0"},[_vm._m(1),(_vm.canEdit)?_c('div',{staticClass:"column is-narrow buttons pb-0"},[_c('b-button',{staticClass:"has-text-weight-bold",attrs:{"type":_vm.updatesObj['products'] ? 'is-success is-light' : 'is-warning',"icon-left":_vm.updatesObj['products'] ? 'chevron-up' : 'pencil',"size":"is-small"},on:{"click":function($event){_vm.updatesObj['products'] = !_vm.updatesObj['products']}}},[_vm._v(_vm._s(_vm.updatesObj["products"] ? "OK" : "Edit"))])],1):_vm._e()]),_c('lean-config-products',{ref:"myConfigProducts",attrs:{"game":{ params: _vm.params, facilities: _vm.facilities },"disable":false,"recap":!_vm.updatesObj['products']},on:{"update":_vm.updateLocalGame}}),_c('div',{staticClass:"bg-transit-slide",class:{
          'bg-transit-slide-open':
            _vm.updatesObj['stations'] || _vm.updatesObj['products'],
          'box-divider-greylane':
            _vm.updatesObj['stations'] || _vm.updatesObj['products']
        }}),_c('div',{staticClass:"box-divider"}),_c('div',{staticClass:"columns mb-0"},[_vm._m(2),(_vm.canEdit)?_c('div',{staticClass:"column is-narrow buttons pb-0"},[_c('b-button',{staticClass:"has-text-weight-bold",attrs:{"type":_vm.updatesObj['stations'] ? 'is-success is-light' : 'is-warning',"icon-left":_vm.updatesObj['stations'] ? 'chevron-up' : 'pencil',"size":"is-small"},on:{"click":function($event){_vm.updatesObj['stations'] = !_vm.updatesObj['stations']}}},[_vm._v(_vm._s(_vm.updatesObj["stations"] ? "OK" : "Edit"))])],1):_vm._e()]),_c('lean-config-stations',{ref:"myConfigStations",attrs:{"game":{ params: _vm.params, facilities: _vm.facilities },"recap":!_vm.updatesObj['stations']},on:{"update":_vm.updateLocalGame,"updatestartingstock":function($event){return _vm.$emit('updatestartingstock')}}}),_c('div',{staticClass:"bg-transit-slide",class:{
          'bg-transit-slide-open':
            _vm.updatesObj['stations'] || _vm.updatesObj['demand'],
          'box-divider-greylane':
            _vm.updatesObj['stations'] || _vm.updatesObj['demand']
        }}),_c('div',{staticClass:"box-divider"}),_c('div',{staticClass:"columns mb-0"},[_vm._m(3),(_vm.canEdit)?_c('div',{staticClass:"column is-narrow buttons pb-0"},[_c('b-button',{staticClass:"has-text-weight-bold",attrs:{"type":_vm.updatesObj['demand'] ? 'is-success is-light' : 'is-warning',"icon-left":_vm.updatesObj['demand'] ? 'chevron-up' : 'pencil',"size":"is-small"},on:{"click":function($event){_vm.updatesObj['demand'] = !_vm.updatesObj['demand']}}},[_vm._v(_vm._s(_vm.updatesObj["demand"] ? "OK" : "Edit"))])],1):_vm._e()]),_c('lean-config-demand',{ref:"myConfigDemand",attrs:{"game":{ params: _vm.params, facilities: _vm.facilities },"my-style":{ 'max-height': _vm.updatesObj['demand'] ? '260px' : '100px' },"disable":false,"recap":!_vm.updatesObj['demand']},on:{"update":_vm.updateLocalGame}}),_c('div',{staticClass:"bg-transit-slide",class:{
          'bg-transit-slide-open': _vm.updatesObj['cost'] || _vm.updatesObj['demand'],
          'box-divider-greylane': _vm.updatesObj['cost'] || _vm.updatesObj['demand']
        }}),_c('div',{staticClass:"box-divider"}),_c('div',{staticClass:"columns mb-0"},[_vm._m(4),(_vm.canEdit)?_c('div',{staticClass:"column is-narrow buttons pb-0"},[_c('b-button',{staticClass:"is-pulled-right has-text-weight-bold",attrs:{"type":_vm.updatesObj['cost'] ? 'is-success is-light' : 'is-warning',"icon-left":_vm.updatesObj['cost'] ? 'chevron-up' : 'pencil',"size":"is-small"},on:{"click":function($event){_vm.updatesObj['cost'] = !_vm.updatesObj['cost']}}},[_vm._v(_vm._s(_vm.updatesObj["cost"] ? "OK" : "Edit"))])],1):_vm._e()]),_c('lean-config-costs',{ref:"myConfigParams",attrs:{"game":{ params: _vm.params, facilities: _vm.facilities },"my-style":{
          'max-height': _vm.updatesObj['cost'] ? '260px' : '100px'
        },"disable":false,"recap":!_vm.updatesObj['cost']},on:{"update":_vm.updateLocalGame}})],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column pb-0"},[_c('h2',{staticClass:"title is-5"},[_vm._v(" General ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('h2',{staticClass:"title is-5"},[_vm._v(" Product templates ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('h2',{staticClass:"title is-5"},[_vm._v(" Workstations ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('h2',{staticClass:"title is-5"},[_vm._v(" Customer Orders ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column pb-0"},[_c('h2',{staticClass:"title is-5"},[_vm._v(" Revenue and Cost variables ")])])}]

export { render, staticRenderFns }